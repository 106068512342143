@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Geologica);

:root {
  --cWidth: clamp(300px, 90vw, 900px);
  --pad: clamp(10px, 15vw, 350px)
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.3s;
  position: relative;
}

#nav {
  position: sticky;
  position: -webkit-sticky;

  top: 0;
  height: 100px;
  /* width: var(--cWidth); */
  /* width: 100vw; */
  width: clamp(0px, 90vw, 1200px);
  padding: 0 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  /* flex-wrap: wrap; */
  z-index: 10;
}

.menu {
  height: 14px;
  width: 15px;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 5vw;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.menuLine {
  height: 2px;
  width: 100%;
  background-color: #000;
}

#nav > h1 {
  color: black;
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 900;
  cursor: pointer;
  font-family: 'Geologica';
}

li {
  display: inline;
  margin-left: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  font-size: clamp(0.95rem, 0.99vw, 10rem);
}

#nav li::after {
	content: "";
	height: 2px;
	width: 0%;
	background-color: #000;
	position: absolute;
	bottom: 0px;
  left: 50%;
  transform: translate(-50%, 3px);
	transition: all ease-in-out 300ms;
}

#nav li:hover::after {
	width: 100%;
}

.sideMenu {
  position: absolute;
  display: none;
  left: -120px;
  top: 0;
  width: 120px;
  height: 100%;
  background-color: #f2f4f7;
}

.menuSticky {
  position: sticky;
  position: -webkit-sticky;
  height: 100vh;
  height: 100svh;
  top: 0;
}

.sideMenu ul {
  margin-left: 5px;
  margin-bottom: 30px;
}

.sideMenu li {
  display: flex;
  margin-top: 20px;
}

.page {
  min-height: calc(100% - 100px);
  min-height: calc(100svh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: clamp(10px, 5vh, 50px) 5vw;
  width: clamp(0px, 90vw, 1200px);
}

.page h1 {
  align-self: flex-start;
  border-bottom: 2px solid black;
}

#centerCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 100px;
  row-gap: clamp(20px, 4vh, 70px);
}

#centerText {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  row-gap: 10px;
  text-align: left;
  max-width: 22ch;
  font-size: clamp(1.8rem, 2vw, 2.25rem);
}

#centerText > h1 {
  font-size: clamp(1.8rem, 2vw, 2.25rem);
  /* white-space: nowrap; */
  /* text-align: center; */
  border: none;
}

#centerText > p {
  font-size: clamp(0.88rem, 1.3vw, 1.1rem);
}

#stackIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: clamp(28px, 2vw, 36px);
  column-gap: 20px;
  margin-top: 20px;
}

.stackImg {
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  position: relative;
}

.stackImg > img {
  height: 100%;
}

.stackSpan {
  position: absolute;
  font-size: clamp(0.7rem, 0.7vw, 0.9rem);
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  transition: 0.6s;
  opacity: 0;
  white-space: nowrap;
}

.spanShow {
  opacity: 1;
}

#stackIcons a, #stackIcons img {
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
}

#centerImg {
  /* width: 30%; */
  max-height: 250px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px #ccc;
}

#divider {
  height: 100%;
  width: 2px;
  background-color: #000;
  margin: 0 15px 0 15px;
  background: linear-gradient(#ffffff00 0%, #000 50%, #ffffff00 100%);
}

.project {
  text-decoration: none;
  color: #000;
  width: 100%;
  column-gap: 40px;
  margin-top: 50px;
  margin-bottom: 40px;
  align-items: center;
}

.project h2 {
  width: 100%;
  margin-bottom: 15px;
}

.projBody {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
}

.projRev {
  flex-direction: row-reverse;
}

.projBody p {
  display: flex;
  flex-direction: column;
  width: 55%;
  text-align: justify;
  row-gap: 10px;
}

.projBody img {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  aspect-ratio: 2.071;
}

.projImg img {
  width: 100%;
  object-fit: cover;
}

#about p {
  text-align: justify;
}

#contact {
  justify-content: flex-start;
}

.contactBlock {
  width: 100%;
  margin-top: 15px;
}

.contactBlock p {
  display: flex;
  column-gap: 10px;
  margin-bottom: 15px;
}

.contactBlock a {
  display: flex;
  column-gap: 15px;
  text-decoration: none;
  color: #000;
}

.dlcv {
  height: 20px;
}

.musicProjs {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 25px;
}

@media only screen and (max-width: 840px) {
  #centerCont {
    flex-direction: column-reverse;
  }

  .projBody {
    flex-direction: column;
    row-gap: 25px;
  }

  .projBody p {
    width: 100%;
  }

  .projBody img {
    width: 100%;
  }
} 

@media only screen and (max-width: 700px) {
  #nav ul {
    display: none;
  }

  .sideMenu {
    display: flex;
  }

  .menu {
    display: flex;
  }

  #nav {
    justify-content: center;
  }
} 

@media only screen and (max-width: 500px) {
  /* #centerText > h1 {
    white-space:normal;
    text-align: left;
  } */

  #stackIcons {
    column-gap: clamp(5px, 2vw, 1000px);
  }
}